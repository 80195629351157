/* eslint-disable react/jsx-no-constructed-context-values */
import { createContext, useContext, useState } from 'react';

export const SelectedAllocationTabContext = createContext({});

export const SelectedAllocationTabProvider = ({ children }) => {
  const [selectedTab, setSelectedTab] = useState(0);

  return (
    <SelectedAllocationTabContext.Provider value={{ selectedTab, setSelectedTab }}>
      {children}
    </SelectedAllocationTabContext.Provider>
  );
};

export const useSelectedAllocationTabProvider = () => {
  const context = useContext(SelectedAllocationTabContext);

  return context;
};
