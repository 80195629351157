import generate from '../generic/generate';

const filterRecruiterPaymentsByOwner = (payments, owner) => {
  return payments?.filter(
    (paymentItem) => Number(paymentItem?.recruiter?.id) === Number(owner?.id)
  );
};

export const generateMessageObject = (data) => {
  return {
    title: `Repasse: ${data?.paymentDayString} de ${generate?.monthName(
      data?.paymentMonth,
      undefined,
      true
    )} de ${data?.targetYear}`,

    subtitle: `Janela de pagamento - ${data?.startDayString} de ${generate?.monthName(
      data?.currentDate,
      undefined,
      true
    )} - ${data?.endDayString} de ${generate?.monthName(data?.nextMonthDate, undefined, true)}`,

    alertMessage: `Realize os pagamentos até: ${data?.paymentDayString} de ${generate?.monthName(
      data?.paymentMonth,
      undefined,
      true
    )} de ${data?.targetYear}`
  };
};

export const generatePaymentsInvoicesTotalValue = (data) => {
  return data?.invoices?.reduce(
    (accumulator, invoiceItem) => accumulator + Number(invoiceItem?.total),
    0
  );
};

export const generateSerializedRecruiterPaymentsByRecruiter = (data) => {
  const resultArray = [];

  const uniqueRecruiters = [];

  data?.forEach((dataItem) => {
    const isRecruiterOnList = uniqueRecruiters?.some(
      (uniqueRecruiterItem) => Number(uniqueRecruiterItem?.id) === Number(dataItem?.recruiter?.id)
    );

    if (!isRecruiterOnList) {
      uniqueRecruiters.push(dataItem?.recruiter);
    }
  });

  uniqueRecruiters?.forEach((uniqueRecruiterIdItem) => {
    const unifiedRecruiterPaymentsByRecruiterItem = {
      recruiter: { ...uniqueRecruiterIdItem },
      total: filterRecruiterPaymentsByOwner(data, uniqueRecruiterIdItem)
        ?.flatMap((filteredPaymentItem) => filteredPaymentItem?.invoices)
        ?.reduce((accumulator, invoiceItem) => accumulator + Number(invoiceItem?.total), 0),
      invoices: filterRecruiterPaymentsByOwner(data, uniqueRecruiterIdItem)?.flatMap(
        (filteredRecruiterPaymentItem) => filteredRecruiterPaymentItem?.invoices
      )
    };

    resultArray.push(unifiedRecruiterPaymentsByRecruiterItem);
  });

  return resultArray;
};

export const handleGeneratePaymentTitleAndSubtitle = (recruiterPaymentWindowDate) => {
  const targetDate = new Date(recruiterPaymentWindowDate);
  const targetDateDay = targetDate?.getDate();
  const targetYear = targetDate?.getFullYear();

  const nextMonthDate = new Date();
  nextMonthDate?.setMonth(Number(nextMonthDate?.getMonth()) + 1);

  const lastMonthDate = new Date();
  lastMonthDate?.setMonth(Number(lastMonthDate?.getMonth()) - 1);

  if (targetDateDay >= 21) {
    return generateMessageObject({
      targetYear,
      nextMonthDate,
      endDayString: '04',
      startDayString: '20',
      paymentDayString: '05',
      currentDate: targetDate,
      paymentMonth: nextMonthDate
    });
  }

  if (targetDateDay >= 5 && targetDateDay <= 20) {
    return generateMessageObject({
      targetYear,
      endDayString: '19',
      startDayString: '05',
      paymentDayString: '20',
      currentDate: targetDate,
      paymentMonth: targetDate,
      nextMonthDate: targetDate
    });
  }

  return generateMessageObject({
    targetYear,
    endDayString: '04',
    startDayString: '20',
    paymentDayString: '05',
    nextMonthDate: targetDate,
    currentDate: lastMonthDate,
    paymentMonth: targetDate
  });
};

export const checkIfAllRelevantRecruiterDataIsFulfilled = (recruiterData) => {
  return (
    !!recruiterData?.paymentDetails &&
    !!recruiterData?.paymentDetails?.pix &&
    !!recruiterData?.paymentDetails?.bankName &&
    !!recruiterData?.paymentDetails?.bankAgency &&
    !!recruiterData?.paymentDetails?.bankAccount
  );
};
