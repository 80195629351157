import nookies, { destroyCookie, parseCookies, setCookie } from 'nookies';
import Cryptography from '../cryptography';

const cookies = () => {
  const getCookies = (context, ctx = undefined) => {
    let cookies;
    try {
      if (context === 'client') {
        cookies = parseCookies();
      }
      if (context === 'server') {
        cookies = nookies.get(ctx);
      }
      return cookies;
    } catch (error) {
      console.error('getCookies Error: ', error);
      return undefined;
    }
  };

  const getCookie = (context, key, ctx = undefined) => {
    let cookies;
    try {
      if (context === 'client') {
        cookies = parseCookies();
      }
      if (context === 'server') {
        cookies = nookies.get(ctx);
      }
      const rawCookie = Cryptography.decrypt(cookies[`hh_${key}`]);

      return JSON.parse(rawCookie);
    } catch (error) {
      return Cryptography.decrypt(cookies[`hh_${key}`]);
    }
  };

  const deleteCookie = (context, key) => {
    try {
      if (context === 'client') {
        destroyCookie(null, `hh_${key}`, {
          path: '/'
        });
        return true;
      }
      if (context === 'server') {
        nookies.destroy(null, `hh_${key}`);
        return true;
      }
      return false;
    } catch (error) {
      console.error('deleteCookie Error: ', error);
      return false;
    }
  };

  const setNewCookie = (context, key, value, time = 60 * 60 * 2, path = '/') => {
    // default -> 2 hour
    try {
      if (context === 'client') {
        setCookie(undefined, `hh_${key}`, Cryptography.encrypt(value), {
          maxAge: time,
          path
        });
      }
      if (context === 'server') {
        nookies.set(undefined, `hh_${key}`, Cryptography.encrypt(value), {
          maxAge: time,
          path
        });
      }
      return true;
    } catch (error) {
      console.error('setNewCookie Error: ', error);
      return false;
    }
  };

  return {
    setNewCookie,
    getCookies,
    getCookie,
    deleteCookie
  };
};

export default cookies;
