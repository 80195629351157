/* eslint-disable react/jsx-no-constructed-context-values */
import { createContext, useContext, useEffect, useState } from 'react';
import cookies from '../../services/cookies';
import Graphql from '../../services/graphql';
import {
  queryById,
  queryWithWhereClausuleFetcher,
  whereClausuleSerializer
} from '../../utils/queriesAndMutations';
import { queryAndMutationsReturns } from '../../utils/queryAndMutationsReturns';
import { checkIfAllRelevantRecruiterDataIsFulfilled } from '../../utils/recruiterTasks/recruiterPayments';

export const UserFirstStepsContext = createContext({});

export const UserFirstStepsProvider = ({ children }) => {
  const [loading, setLoading] = useState(true);
  const [firstSteps, setFirstSteps] = useState(null);
  const [accordionPaddingOpened, setAccordionPaddingClosed] = useState(null);

  const { getCookie } = cookies();
  const user = getCookie('client', 'user');
  const token = getCookie('client', 'token');
  const userType = getCookie('client', 'userType');

  const { gqlQuery, gqlMutation } = Graphql(token);

  const getDefaultFirstStepsProgress = async () => {
    try {
      const queryResult = await gqlQuery('allocationConfig', '', 'defaultFirstStepsProgress');

      if (!queryResult?.data?.allocationConfig?.defaultFirstStepsProgress) {
        throw new Error(' Erro ao buscar os dados padrão de progresso dos primeiros passos.');
      }

      return queryResult?.data?.allocationConfig?.defaultFirstStepsProgress;
    } catch (error) {
      console.error('[FIRST STEPS PROGRESS]: getDefaultFirstStepsProgress function', error);

      return error;
    }
  };

  const countAllocationsByRecruiterId = async (userTypeData) => {
    try {
      const queryWhereClausule = [{ 'recruitersPool.id': userTypeData?.id }];
      const serializedQueryWhereClausuleString = whereClausuleSerializer(queryWhereClausule);

      const queryResult = await queryWithWhereClausuleFetcher(
        'allocations/count',
        token,
        serializedQueryWhereClausuleString
      );

      return !!queryResult;
    } catch (error) {
      console.error('[FIRST STEPS PROGRESS]: countAllocationsByRecruiterId function', error);

      return false;
    }
  };

  const countApplicantsRegisteredOnRecruiterNetwork = async (userTypeData) => {
    try {
      const collectionId = userTypeData?.recruiter_network?.id;

      const queryResult = await queryById('recruiter-networks', token, collectionId);

      return !!queryResult?.applicants?.length;
    } catch (error) {
      console.error(
        '[FIRST STEPS PROGRESS]: countApplicantsRegisteredOnRecruiterNetwork function',
        error
      );

      return false;
    }
  };

  const getUserTypeFullProfileAndPaymentData = async (userTypeData) => {
    try {
      const queryResult = await gqlQuery(
        userTypeData?.type,
        'id: $userTypeId ',
        queryAndMutationsReturns[userTypeData?.type],
        {
          variables: { userTypeId: userTypeData?.typeId },
          queryParams: '$userTypeId: ID!',
          queryName: 'getUserTypeProfileData'
        }
      );

      return queryResult?.data[userTypeData?.type];
    } catch (error) {
      console.error('[FIRST STEPS PROGRESS]: getUserTypeFullProfileAndPaymentData function', error);

      return error;
    }
  };

  const getFirstStepsProgress = async (userOwnerId) => {
    try {
      const queryWhereClausule = [{ userOwner: userOwnerId }];
      const serializedQueryWhereClausuleString = whereClausuleSerializer(queryWhereClausule);

      const queryResult = await queryWithWhereClausuleFetcher(
        'first-steps-progresses',
        token,
        serializedQueryWhereClausuleString
      );

      return queryResult[0];
    } catch (error) {
      console.error('[FIRST STEPS PROGRESS]: getFirstStepsProgress function', error);

      return error;
    }
  };

  const createFirstStepsProgress = async (userOwnerId) => {
    try {
      const defaultFirstStepsProgressData = await getDefaultFirstStepsProgress();

      const mutationResult = await gqlMutation(
        'createFirstStepsProgress',
        'input: $input',
        `
          firstStepsProgress {
            id
            title
            donePercentage
            shortDescription
            steps { name achieved quantity triggerQuestion }
          }
        `,
        {
          mutationName: 'createFirstStepsProgress',
          mutationParams: '$input: createFirstStepsProgressInput',
          variables: {
            input: { data: { ...defaultFirstStepsProgressData, userOwner: userOwnerId } }
          }
        }
      );

      return mutationResult?.data?.createFirstStepsProgress?.firstStepsProgress;
    } catch (error) {
      console.error('[FIRST STEPS PROGRESS]: createFirstStepsProgress function', error);

      return error;
    }
  };

  const updateFirstStepsProgress = async (firstStepsProgressId, updateData) => {
    try {
      const mutationResult = await gqlMutation(
        'updateFirstStepsProgress',
        'input: $input',
        `
          firstStepsProgress {
            id
            donePercentage
            steps { name achieved quantity triggerQuestion }
          }
        `,
        {
          mutationName: 'updateFirstStepsProgress',
          mutationParams: '$input: updateFirstStepsProgressInput',
          variables: { input: { data: updateData, where: { id: firstStepsProgressId } } }
        }
      );

      return mutationResult?.data?.updateFirstStepsProgress?.firstStepsProgress;
    } catch (error) {
      console.error('[FIRST STEPS PROGRESS]: updateFirstStepsProgress function', error);

      return error;
    }
  };

  const handleCheckIfUserTypeProfileDataIsFull = async (targetUserTypeData) => {
    const allDataWasFulfilled = Object.values(targetUserTypeData)?.every((item) => item !== null);

    return allDataWasFulfilled;
  };

  const handleUserFirstStepsProgressExistence = async (userOwnerId) => {
    try {
      const userFirstStepsProgress = await getFirstStepsProgress(userOwnerId);

      if (userFirstStepsProgress?.id) {
        setFirstSteps(userFirstStepsProgress);

        return userFirstStepsProgress;
      }

      const newlyCreatedFirstStepProgress = await createFirstStepsProgress(userOwnerId);

      setFirstSteps(newlyCreatedFirstStepProgress);

      return newlyCreatedFirstStepProgress;
    } catch (error) {
      console.error(
        '[FIRST STEPS PROGRESS]: handleUserFirstStepsProgressExistence function',
        error
      );

      return error;
    }
  };

  const handleAutomaticUserFirstStepsProgressFlux = async (firstStepsData, userTypeData) => {
    try {
      setLoading(true);
      let updatedDonePercentage = firstStepsData?.donePercentage;

      let triggerDatabaseUpdate = false;

      const triggerQuestionsHandlers = {
        isOnboardingDone: () => !user?.firstLogin,
        isUserProfileCompleted: handleCheckIfUserTypeProfileDataIsFull,
        isUserRegisteredInSomeAllocation: countAllocationsByRecruiterId,
        isUserWalletConfigured: checkIfAllRelevantRecruiterDataIsFulfilled,
        asUserRegisteredAnApplicant: countApplicantsRegisteredOnRecruiterNetwork
      };

      const updatedFirstStepsData = await Promise.all(
        firstStepsData?.steps?.map(async (step) => {
          let currentStep = step;
          let isStepRequirementsMet;

          if (!step?.achieved) {
            isStepRequirementsMet = await triggerQuestionsHandlers[step?.triggerQuestion](
              userTypeData
            );
          }

          if (isStepRequirementsMet) {
            const newPercentage = updatedDonePercentage + Number(step?.quantity);

            updatedDonePercentage = newPercentage > 100 ? 100 : newPercentage;

            currentStep = { ...step, achieved: true };
          }

          if (isStepRequirementsMet && !triggerDatabaseUpdate) {
            triggerDatabaseUpdate = true;
          }

          return currentStep;
        })
      );

      if (triggerDatabaseUpdate) {
        await updateFirstStepsProgress(firstStepsData?.id, {
          steps: updatedFirstStepsData,
          donePercentage: updatedDonePercentage
        });

        setFirstSteps((previousValues) => ({
          ...previousValues,
          steps: updatedFirstStepsData,
          donePercentage: updatedDonePercentage
        }));
      }

      return setLoading(false);
    } catch (error) {
      setLoading(false);

      console.error(
        '[FIRST STEPS PROGRESS]: handleAutomaticUserFirstStepsProgressFlux function',
        error
      );

      return error;
    }
  };

  useEffect(() => {
    if (userType?.type !== 'recruiter') {
      return () => {};
    }

    (async () => {
      setLoading(true);

      const userTypeData = await getUserTypeFullProfileAndPaymentData(userType);

      const userFirstStepsProgressData = await handleUserFirstStepsProgressExistence(user?.id);
      let isSomeStepNotAchieved;

      if (userFirstStepsProgressData?.id) {
        isSomeStepNotAchieved = userFirstStepsProgressData?.steps?.some((step) => !step?.achieved);
      }

      if (isSomeStepNotAchieved) {
        await handleAutomaticUserFirstStepsProgressFlux(userFirstStepsProgressData, userTypeData);
      }

      setLoading(false);
    })();

    return () => {};
  }, []);

  useEffect(() => {
    const implementsPaddingOnAccordion = firstSteps?.donePercentage < 100;

    if (implementsPaddingOnAccordion) {
      setAccordionPaddingClosed('1.25rem 1rem');
    }
  }, [firstSteps?.donePercentage]);

  return (
    <UserFirstStepsContext.Provider
      value={{
        // * STATES
        loading,
        firstSteps,
        accordionPaddingOpened,
        // * STATE SETTERS
        setLoading,
        setFirstSteps,
        setAccordionPaddingClosed
        // * DISCLOSURES
        // * CRUD FUNCTIONS
        // * HANDLERS
      }}
    >
      {children}
    </UserFirstStepsContext.Provider>
  );
};

export const useUserFirstStepsProvider = () => {
  const context = useContext(UserFirstStepsContext);

  return context;
};
