import { extendTheme } from '@chakra-ui/react';
import { StepsStyleConfig as Steps } from 'chakra-ui-steps';

const theme = extendTheme({
  components: {
    Steps
  },
  fonts: {
    body: 'Inter, Outfit,  sans-serif',
    heading: 'Inter, Outfit, sans-serif',
    mono: 'Inter, Outfit,  sans-serif'
  },
  colors: {
    primary: {
      50: '#fef5f5',
      100: '#fcd8d8',
      200: '#f9b4b4',
      300: '#f68585',
      400: '#f46666',
      500: '#db4a4a',
      600: '#b93f3f',
      700: '#953232',
      800: '#7e2b2b',
      900: '#5c1f1f'
    }
  },
  breakpoints: {
    sm: '320px',
    md: '768px',
    lg: '960px',
    xl: '1200px',
    '2xl': '1536px'
  },
  fontSizes: {
    xs: '11px',
    sm: '13px',
    md: '16px',
    lg: '18px',
    xl: '20px',
    '2xl': '24px',
    '3xl': '28px',
    '4xl': '32px',
    '5xl': '40px',
    '6xl': '50px',
    '7xl': '60px',
    '8xl': '90px',
    '9xl': '128px'
  }
});

export default theme;
