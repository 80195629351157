import qs from 'qs';

export const whereClausuleSerializer = (whereDataArray, orClauseDataArray = null) => {
  const formattedQueryString = qs.stringify({
    _where: [...whereDataArray],
    ...(orClauseDataArray && {
      _or: [...orClauseDataArray]
    })
  });

  return formattedQueryString;
};

export const queryWithWhereClausuleFetcher = async (
  collection,
  token,
  whereClausule,
  queryParams
) => {
  const queryResult = await fetch(
    `${process.env.NEXT_PUBLIC_API_URL}/${collection}?${whereClausule}${queryParams || ''}`,
    {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json'
      }
    }
  ).then((response) => response.json());

  return queryResult;
};

export const queryById = async (collection, token, collectionId) => {
  const queryResult = await fetch(
    `${process.env.NEXT_PUBLIC_API_URL}/${collection}/${collectionId}`,
    {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json'
      }
    }
  ).then((response) => response.json());

  return queryResult;
};
