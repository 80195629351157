import { Button, HStack, Stack, Text } from '@chakra-ui/react';

export const IncidentsCloseableHeroAlert = ({
  handler,
  showIncidentAlert,
  type = 'Instabilidade no servidor',
  description = 'O que raios está a acontecer'
}) => {
  if (!showIncidentAlert) {
    return null;
  }

  return (
    <Stack
      zIndex={999}
      width="100%"
      color="#B54708"
      position="absolute"
      padding="16px 1.5rem"
      borderBottomWidth="1px"
      backgroundColor="#FFFAEB"
      borderBottomColor="#F79009"
    >
      <HStack justify="space-between">
        <HStack>
          <Stack spacing={0} justify="center">
            <Text fontSize="0.9rem" fontWeight={600} lineHeight="1.25rem">
              {type}
            </Text>

            <Text fontSize="0.9rem" fontWeight={400} lineHeight="1.25rem">
              {description}
            </Text>
          </Stack>
        </HStack>

        <Button variant="outline" onClick={handler}>
          X
        </Button>
      </HStack>
    </Stack>
  );
};
