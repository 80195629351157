import appMocks from '../../mocks/appMocks.json';

const stateName = (target) => {
  const result = appMocks?.states?.find(({ uf }) => uf === target);

  if (!result?.name) {
    return '';
  }

  return result?.name;
};

const linkedinURL = (name, isCompany = true) => {
  const nameWithoutAtSign = name?.replace('@', '');

  if (!name) {
    return '';
  }

  if (isCompany) {
    return `https://www.linkedin.com/company/${nameWithoutAtSign}`;
  }

  return `https://www.linkedin.com/in/${nameWithoutAtSign}`;
};

const recruiterPrices = (catalogServices, actingArea, seniority) => {
  const category = catalogServices?.find((service) => service?.category === actingArea);

  const catalogService = category?.seniorities?.find(
    (catalogSeniority) =>
      catalogSeniority?.name
        ?.normalize('NFD')
        ?.replace(/[\u0300-\u036f]/g, '')
        ?.toLowerCase() ===
      seniority
        ?.normalize('NFD')
        ?.replace(/[\u0300-\u036f]/g, '')
        ?.toLowerCase()
  );

  if (!catalogService) {
    return null;
  }

  return {
    name: catalogService?.name,
    price: catalogService?.price
  };
};

const deadline = (date, subtractDays = 35) => {
  const deadline = new Date(date);
  const day = deadline.getDate();

  deadline.setDate(day - subtractDays);

  return deadline;
};

const daysLeftFromTimestamp = (timestamp) => {
  const now = new Date();
  const date = new Date(timestamp);
  const diff = date.getTime() - now.getTime();
  const days = Math.floor(diff / (1000 * 60 * 60 * 24));
  return days;
};

const daysLeftFromDateObject = (date) => {
  const now = new Date();
  const difference = date - now;

  const days = Math.floor(difference / (1000 * 60 * 60 * 24));
  return days;
};

const daysLeft = (date) => {
  if (date instanceof Date) {
    return daysLeftFromDateObject(date);
  }

  const validateTimestamp = new Date(date);

  if (validateTimestamp instanceof Date) {
    return daysLeftFromTimestamp(date);
  }

  return null;
};

const currentMonth = () => {
  const month = new Date()?.toLocaleDateString('pt-BR', { month: 'long' });

  return month.charAt(0).toUpperCase() + month.substring(1).toLowerCase();
};

const customErrors = (name, message) => {
  throw new Error({ name, message });
};

const timestampFromDateObject = (targetDate = new Date()) => {
  return Date.parse(targetDate) / 1000;
};

const recruiterPaymentWindowDate = () => {
  const paymentWindowsDays = {
    first: 6,
    second: 21
  };

  const newDate = new Date();
  const currentDate = {
    day: newDate.getDate(),
    monthIndex: newDate.getMonth()
  };

  if (currentDate.day < paymentWindowsDays.first) {
    newDate.setDate(paymentWindowsDays.first - 1);
    newDate.setMonth(currentDate.monthIndex);

    return newDate;
  }

  if (currentDate.day >= paymentWindowsDays.first && currentDate.day < paymentWindowsDays.second) {
    newDate.setDate(paymentWindowsDays.second - 1);
    newDate.setMonth(currentDate.monthIndex);

    return newDate;
  }

  newDate.setDate(paymentWindowsDays.first - 1);
  newDate.setMonth(currentDate.monthIndex + 1);

  return newDate;
};

const stringWithFirstLetterCapitalized = (string) => {
  const capitalizedFirstLetter = string.charAt(0).toUpperCase();
  const restOfTheString = string.slice(1);

  return capitalizedFirstLetter + restOfTheString;
};

const monthName = (date, locale = 'pt-BR', capitalize = false) => {
  const targetMonthName = date?.toLocaleDateString(locale, { month: 'long' });

  if (capitalize) {
    return stringWithFirstLetterCapitalized(targetMonthName);
  }

  return targetMonthName;
};

const defaultPortugueseDateFormat = (targetDate) => {
  const targetDay = targetDate?.getDate();
  const targetYear = targetDate?.getFullYear();
  const targetMonthName = monthName(targetDate, undefined, true);

  return `${targetDay} de ${targetMonthName} de ${targetYear}`;
};

const randomHuntlyEmail = () => {
  return `${Math.floor(10000000 + Math.random() * 90000000)}-applicant@huntly.com.br`;
};

const generate = {
  currentMonth,
  customErrors,
  daysLeft,
  deadline,
  defaultPortugueseDateFormat,
  linkedinURL,
  monthName,
  randomHuntlyEmail,
  recruiterPaymentWindowDate,
  recruiterPrices,
  stateName,
  stringWithFirstLetterCapitalized,
  timestampFromDateObject
};

export default generate;
