import { useToast } from '@chakra-ui/react';
import * as Sentry from '@sentry/browser';
import axios from 'axios';
import { createContext, useEffect, useMemo, useReducer } from 'react';
import cookies from '../../services/cookies';
import Graphql from '../../services/graphql';
import MySwal from '../../services/sweet-alert';
import reducer from './reducer';

export const GoogleContext = createContext({});
const { getCookie } = cookies();

const initialState = {
  googleRefreshToken: null,
  isGoogleLoggedIn: false,
  showError: false
};

export default function GoogleProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const googleRefreshToken = useMemo(() => state.googleRefreshToken, [state.googleRefreshToken]);
  const isGoogleLoggedIn = useMemo(() => state.isGoogleLoggedIn, [state.isGoogleLoggedIn]);
  const toast = useToast();

  const handleSetGoogleRefreshToken = (value) =>
    dispatch({ type: 'updateRefreshToken', payload: value });

  const handleAccountConnectSuccess = (res) => {
    const { code } = res;
    const user = getCookie('client', 'user');
    const token = getCookie('client', 'token');
    axios
      .post(
        `${process.env.NEXT_PUBLIC_API_URL}/google/create-tokens`,
        { code, id: user.id },
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      )
      .then((res) => {
        handleSetGoogleRefreshToken(res.data.tokens.google_refresh_token);
      })
      .catch((error) => {
        Sentry.captureMessage(
          `Erro ao criar tokens do Google na API : ${JSON.stringify(error)}`,
          'fatal'
        );
        MySwal.fire({
          icon: 'error',
          title: 'Algo deu errado ao conectar com Google...',
          html: 'Aconteceu um erro inesperado ao tentar conectar-se Google. Tente novamente mais tarde.'
        });
      });
  };

  const handleAccountConnectFailure = (err) => {
    Sentry.captureMessage(`Erro ao conectar com a conta Google : ${JSON.stringify(err)}`, 'fatal');
    if (state.showError === false) {
      toast({
        title: 'Erro ao conectar com Google',
        description: 'Ocorreu um erro ao realizar a conexão com o Google',
        status: 'error',
        duration: 6000,
        isClosable: true
      });
      dispatch({ type: 'updateShowError' });
    }
  };

  const handleCreateCalendarEvent = (eventData, callback) => {
    const token = getCookie('client', 'token');
    axios
      .post(
        `${process.env.NEXT_PUBLIC_API_URL}/google/create-event`,
        {
          tokens: {
            refresh_token: googleRefreshToken
          },
          ...eventData
        },
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      )
      .then((res) => {
        if (res?.data?.event === '') throw new Error('Erro ao criar evento'); // Retorna erro ocorrido no Backend
        if (typeof callback === 'function') {
          callback(res?.data?.event, null);
        }
      })
      .catch((err) => {
        Sentry.captureMessage(
          `Erro ao criar um evento ao calendário Google : ${JSON.stringify(err)}`,
          'error'
        );
        if (typeof callback === 'function') {
          callback(null, new Error(err));
        }
      });
  };

  useEffect(() => {
    (async () => {
      const user = getCookie('client', 'user');

      if (!user?.id) {
        return;
      }

      const token = getCookie('client', 'token');
      const { gqlQuery } = Graphql(token);

      try {
        const fetchedUser = await gqlQuery('user', 'id: $id', 'google_refresh_token', {
          variables: {
            id: user.id
          },
          queryParams: '$id: ID!'
        });

        if (fetchedUser?.data?.user?.google_refresh_token) {
          handleSetGoogleRefreshToken(fetchedUser?.data?.user?.google_refresh_token);
        }
      } catch (err) {
        Sentry.captureMessage(
          `Erro ao buscar o refresh token do Google : ${JSON.stringify(err)}`,
          'fatal'
        );
      }
    })();
  }, []);

  const ProviderValues = useMemo(
    () => ({
      googleRefreshToken,
      isGoogleLoggedIn,
      handleAccountConnectSuccess,
      handleAccountConnectFailure,
      handleCreateCalendarEvent
    }),
    [googleRefreshToken, isGoogleLoggedIn]
  );

  return <GoogleContext.Provider value={ProviderValues}>{children}</GoogleContext.Provider>;
}
