import { ChakraProvider } from '@chakra-ui/react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { AnimatePresence } from 'framer-motion';
import mixpanel from 'mixpanel-browser';
import Head from 'next/head';
import { useRouter } from 'next/router';
import Script from 'next/script';
import qs from 'qs';
import { useEffect, useState } from 'react';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import CookieConsent from 'react-cookie-consent';
import { hotjar } from 'react-hotjar';
import { RecoilRoot } from 'recoil';
import Analytics from '../components/Analytics';
import { IncidentsCloseableHeroAlert } from '../components/Heroes/IncidentHeroAlert';
import { IntercomProvider } from '../context/intercom/IntercomProvider';
import { SelectedAllocationTabProvider } from '../context/navigation/selectedAllocationTabs';
import AppProvider from '../hooks';
import * as gtag from '../lib/gtag';
import cookies from '../services/cookies';
import '../styles/alignmentMeeting.css';
import '../styles/design.css';
import '../styles/gamification.css';
import '../styles/global.css';
import '../styles/huntlyCalendar.css';
import theme from '../theme';

function App({ Component, pageProps }) {
  const [activeIncident, setActiveIncident] = useState({});
  const [showIncidentAlert, setShowIncidentAlert] = useState(false);

  const router = useRouter();
  const queryClient = new QueryClient();

  const handleShowIncidentAlert = () => {
    setShowIncidentAlert((previousValue) => !previousValue);
  };

  const getIncidentsQuery = async (queryStart = 0, queryLimit = 50) => {
    try {
      const { getCookie } = cookies();
      const token = getCookie('client', 'token', '/');

      const query = qs.stringify({
        _where: { _or: [{ done_null: true }, { done: false }] }
      });

      const activeIncidents = await fetch(
        `${process.env.NEXT_PUBLIC_API_URL}/incidents?${query}&_start=${queryStart}&_limit=${queryLimit}&_sort=updated_at:DESC`,
        {
          method: 'GET',
          headers: {
            ...(token && { Authorization: `Bearer ${token}` }),
            'Content-Type': 'application/json'
          }
        }
      ).then((response) => response.json());

      return activeIncidents[0] || null;
    } catch (error) {
      console.error('Erro ao buscar stages: ', error);

      return null;
    }
  };

  useEffect(() => {
    hotjar.initialize(Number(process.env.NEXT_PUBLIC_HOTJAR_ID), 6);
    mixpanel.init(process.env.NEXT_PUBLIC_MIXPANEL || '', {
      debug: true,
      ignore_dnt: true
    });

    getIncidentsQuery()?.then((result) => {
      if (result) {
        setActiveIncident(result);
        setShowIncidentAlert(true);
      }
    });

    return () => {};
  }, []);

  useEffect(() => {
    const handleRouteChange = (url) => {
      gtag.pageview(url);
    };

    router.events.on('routeChangeComplete', handleRouteChange);
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router.events]);

  return (
    <QueryClientProvider client={queryClient}>
      <RecoilRoot>
        <IntercomProvider>
          <ChakraProvider theme={theme}>
            <Head>
              <title>App - Huntly</title>
            </Head>
            {typeof window !== 'undefined' && !window.mixpanel && (
              <Script
                id="mixpanel"
                strategy="afterInteractive"
                dangerouslySetInnerHTML={{
                  __html: `
                    (function(f,b){if(!b.__SV){var e,g,i,h;window.mixpanel=b;b._i=[];b.init=function(e,f,c){function g(a,d){var b=d.split(".");2==b.length&&(a=a[b[0]],d=b[1]);a[d]=function(){a.push([d].concat(Array.prototype.slice.call(arguments,0)))}}var a=b;"undefined"!==typeof c?a=b[c]=[]:c="mixpanel";a.people=a.people||[];a.toString=function(a){var d="mixpanel";"mixpanel"!==c&&(d+="."+c);a||(d+=" (stub)");return d};a.people.toString=function(){return a.toString(1)+".people (stub)"};i="disable time_event track track_pageview track_links track_forms track_with_groups add_group set_group remove_group register register_once alias unregister identify name_tag set_config reset opt_in_tracking opt_out_tracking has_opted_in_tracking has_opted_out_tracking clear_opt_in_out_tracking start_batch_senders people.set people.set_once people.unset people.increment people.append people.union people.track_charge people.clear_charges people.delete_user people.remove".split(" ");
                    for(h=0;h<i.length;h++)g(a,i[h]);var j="set set_once union unset remove delete".split(" ");a.get_group=function(){function b(c){d[c]=function(){call2_args=arguments;call2=[c].concat(Array.prototype.slice.call(call2_args,0));a.push([e,call2])}}for(var d={},e=["get_group"].concat(Array.prototype.slice.call(arguments,0)),c=0;c<j.length;c++)b(j[c]);return d};b._i.push([e,f,c])};b.__SV=1.2;e=f.createElement("script");e.type="text/javascript";e.async=!0;e.src="undefined"!==typeof MIXPANEL_CUSTOM_LIB_URL?
                    MIXPANEL_CUSTOM_LIB_URL:"file:"===f.location.protocol&&"//cdn.mxpnl.com/libs/mixpanel-2-latest.min.js".match(/^\\/\\//)?"https://cdn.mxpnl.com/libs/mixpanel-2-latest.min.js":"//cdn.mxpnl.com/libs/mixpanel-2-latest.min.js";g=f.getElementsByTagName("script")[0];g.parentNode.insertBefore(e,g)}})(document,window.mixpanel||[]);
                    mixpanel.init("${process.env.NEXT_PUBLIC_MIXPANEL}", {debug: true});
                    // mixpanel.track('Sign up');
                    `
                }}
              />
            )}

            <AppProvider>
              <AnimatePresence exitBeforeEnter>
                <SelectedAllocationTabProvider>
                  <IncidentsCloseableHeroAlert
                    type={activeIncident?.type}
                    handler={handleShowIncidentAlert}
                    showIncidentAlert={showIncidentAlert}
                    description={activeIncident?.description}
                  />

                  <Component {...pageProps} key={router.asPath} />
                </SelectedAllocationTabProvider>
              </AnimatePresence>
              <Analytics />
              <CookieConsent
                buttonText="Aceito"
                overlay
                flipButtons
                cookieName="hh_accept_cookies"
                style={{ background: '#F25252', fontSize: '16px' }}
                buttonStyle={{
                  backgroundColor: '#fff',
                  color: '#000',
                  fontSize: '16px',
                  borderRadius: '5px'
                }}
                expires={150}
              >
                <p>Nosso site utiliza cookies para seu funcionamento e experiência do usuário.</p>
              </CookieConsent>
              <ReactQueryDevtools />
            </AppProvider>
          </ChakraProvider>
        </IntercomProvider>
      </RecoilRoot>
    </QueryClientProvider>
  );
}

export default App;
