/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable consistent-return */
import React, { createContext, useContext, useEffect, useState } from 'react';
import { getStorageItem, setStorageItem } from '../../utils/localStorage';

const AuthContext = createContext();

const CartProvider = ({ children }) => {
  const [position, setPosition] = useState({});
  // const [total, setTotal] = useState(0);

  useEffect(() => {
    const storage = getStorageItem('cart');
    setPosition(storage);
  }, []);

  const addPosition = () => {
    setPosition({ ...position, quantity: position.quantity + 1 });
    setStorageItem('cart', { ...position, quantity: position.quantity + 1 });
  };

  const addInitialPosition = (item, quantity = 1) => {
    setPosition({ ...item, quantity });
    setStorageItem('cart', { ...item, quantity });
  };

  const removePosition = () => {
    if (position.quantity > 1) {
      setPosition({ ...position, quantity: position.quantity - 1 });
      setStorageItem('cart', { ...position, quantity: position.quantity - 1 });
    }
  };

  const saveCart = (item) => {
    setPosition({ ...position, ...item });
    setStorageItem('cart', { ...position, ...item });
  };

  const getTotal = () => {
    const total = Number(position?.catalogService?.price) * Number(position?.quantity);
    return total;
  };

  return (
    <AuthContext.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{
        position,
        addPosition,
        removePosition,
        addInitialPosition,
        saveCart,
        getTotal
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

function useCart() {
  const context = useContext(AuthContext);

  return context;
}

export { CartProvider, useCart };
