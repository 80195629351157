const APP_KEY = 'HEADHUNTERS';

export function getStorageItem(key) {
  // no Next via Server/Static não tem window
  if (typeof window === 'undefined') return false;

  const data = window.localStorage.getItem(`${APP_KEY}_${key}`);

  let storage = {};
  try {
    storage = JSON.parse(data);
  } catch (error) {
    console.error('error', error);
  }

  return storage;
}

export function setStorageItem(key, value) {
  // no Next via Server/Static não tem window
  if (typeof window === 'undefined') return;

  const data = JSON.stringify(value);
  window.localStorage.setItem(`${APP_KEY}_${key}`, data);
}

export function removeStorageItem(key) {
  if (typeof window !== 'undefined') {
    window.localStorage.removeItem(`${APP_KEY}_${key}`);
  }
}
