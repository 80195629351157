import * as Sentry from '@sentry/nextjs';
import React, { useEffect } from 'react';
import { RecoilRoot } from 'recoil';
import GoogleProvider from '../context/google';
import { UserFirstStepsProvider } from '../context/sidebars/userFirstSteps';
import cookies from '../services/cookies';
import { CartProvider } from './useCart';
import { EvaluationProvider } from './useEvaluation';

const { getCookie } = cookies();

const AppProvider = ({ children }) => {
  useEffect(() => {
    const user = getCookie('client', 'user');
    const userType = getCookie('client', 'userType');

    if (user !== '') {
      Sentry.setUser({
        id: user.id,
        username: `${user.name} ${user.subname}`,
        email: user.email,
        role: userType.type,
        roleId: userType.typeId
      });
    } else {
      Sentry.setUser({
        state: 'User not Logged'
      });
    }
  }, []);

  return (
    <GoogleProvider>
      <RecoilRoot>
        <CartProvider>
          <EvaluationProvider>
            <UserFirstStepsProvider>{children}</UserFirstStepsProvider>
          </EvaluationProvider>
        </CartProvider>
      </RecoilRoot>
    </GoogleProvider>
  );
};

export default AppProvider;
