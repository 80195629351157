export const queryAndMutationsReturns = {
  recruiter: `
    id
    cpf
    rate
    name
    cnpj
    uuid
    email
    about
    phone
    admin
    subname
    youtube
    linkedin
    imageUrl
    isChecked
    workModel
    created_at
    updated_at
    user { id }
    openForJobs
    published_at
    corporateName
    workExperience
    languages { name }
    address { address }
    specialities { name }
    recruiter_network { id }
    recruitingTools { name }
    specialitiesLevels { id }
    catalog_services { category }
    paymentDetails { pix bankName bankAgency bankAccount }
  `,

  company: `
    id
    name
    cnpj
    email
    phone
    sector
    website
    imageUrl
    linkedin
    created_at
    updated_at
    user { id }
    admin { id }
    published_at
    acceptsBilling
    employee { id }
    address { address }
    stripe_customer { id }
    showOnRecruiterProfile
  `
};
