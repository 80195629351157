const reducer = (state, action) => {
  switch (action.type) {
    case 'updateRefreshToken':
      return {
        ...state,
        googleRefreshToken: action.payload,
        isGoogleLoggedIn: true
      };

    case 'updateShowError':
      return {
        ...state,
        showError: !state.showError
      };

    default:
      return state;
  }
};

export default reducer;
