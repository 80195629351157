import * as Sentry from '@sentry/nextjs';
import axios from 'axios';

const Graphql = (token = undefined) => {
  const fetchQuery = async (collectionName, collectionParams, collectionReturns, args) => {
    try {
      const URL = `${process.env.NEXT_PUBLIC_API_URL}/graphql`;

      const gqlBody = {
        query: `query ${args?.queryName || ''}${args?.queryParams ? '(' : ''}${
          args?.queryParams ? args?.queryParams : ''
        }${args?.queryParams ? ')' : ''} {
            ${collectionName}${collectionParams !== '' ? '(' : ''}${
          collectionParams !== '' ? collectionParams : ''
        }${collectionParams !== '' ? ')' : ''} {
              ${collectionReturns}
            }
          }`,
        variables: args?.variables || {}
      };

      const response = await fetch(URL, {
        method: 'POST',
        headers: {
          Authorization: token ? `Bearer ${token}` : '',
          'Content-Type': 'application/json; charset=UTF-8'
          // 'Accept-Encoding': '*'
        },
        body: JSON.stringify({ ...gqlBody })
      });

      return response.json();
    } catch (error) {
      const dataError = {
        message: error?.message,
        data: error?.response?.data?.errors
      };

      Sentry.captureMessage(
        `[Request] Erro ao realizar a requisição "Query": ${JSON.stringify(dataError)}`,
        'fatal'
      );

      return {
        error: dataError
      };
    }
  };

  const gqlQuery = async (collectionName, collectionParams, collectionReturns, args) => {
    try {
      const headersList = {
        Authorization: token ? `Bearer ${token}` : '',
        'Content-Type': 'application/json; charset=UTF-8',
        'Accept-Encoding': '*'
      };

      const gqlBody = {
        query: `query ${args?.queryName || ''}${args?.queryParams ? '(' : ''}${
          args?.queryParams ? args?.queryParams : ''
        }${args?.queryParams ? ')' : ''} {
            ${collectionName}${collectionParams !== '' ? '(' : ''}${
          collectionParams !== '' ? collectionParams : ''
        }${collectionParams !== '' ? ')' : ''} {
              ${collectionReturns}
            }
          }`,
        variables: args?.variables || {}
      };

      const bodyContent = JSON.stringify(gqlBody);

      const reqOptions = {
        url: `${process.env.NEXT_PUBLIC_API_URL}/graphql`,
        method: 'POST',
        headers: headersList,
        data: bodyContent
      };

      const response = await axios.request(reqOptions);

      return response.data;
    } catch (error) {
      const dataError = {
        message: error?.message,
        data: error?.response?.data?.errors
      };

      Sentry.captureMessage(
        `[Request] Erro ao realizar a requisição "Query": ${JSON.stringify(dataError)}`,
        'fatal'
      );

      return {
        error: dataError
      };
    }
  };

  const gqlMutation = async (collectionName, collectionParams, collectionReturns, args) => {
    try {
      const headersList = {
        Authorization: token ? `Bearer ${token}` : '',
        'Content-Type': 'application/json; charset=UTF-8',
        'Accept-Encoding': '*'
      };

      const gqlBody = {
        query: `mutation ${args?.mutationName || ''}${args?.mutationParams ? '(' : ''}${
          args?.mutationParams ? args?.mutationParams : ''
        }${args?.mutationParams ? ')' : ''} {
            ${collectionName}${collectionParams !== '' ? '(' : ''}${
          collectionParams !== '' ? collectionParams : ''
        }${collectionParams !== '' ? ')' : ''} {
              ${collectionReturns}
            }
          }`,
        variables: args?.variables || {}
      };

      const bodyContent = JSON.stringify(gqlBody);

      const reqOptions = {
        url: `${process.env.NEXT_PUBLIC_API_URL}/graphql`,
        method: 'POST',
        headers: headersList,
        data: bodyContent
      };

      const response = await axios.request(reqOptions);

      return response.data;
    } catch (error) {
      const dataError = {
        message: error?.message,
        data: error?.response?.data?.errors
      };

      Sentry.captureMessage(
        `[Request] Erro ao realizar a requisição "Mutation": ${JSON.stringify(dataError)}`,
        'fatal'
      );

      return {
        error: dataError
      };
    }
  };

  return { fetchQuery, gqlQuery, gqlMutation };
};

export default Graphql;
