import React, { createContext, useContext, useEffect, useState } from 'react';
import { getStorageItem, setStorageItem } from '../../utils/localStorage';

const EvaluationContext = createContext();

const EvaluationProvider = ({ children }) => {
  const [evaluation, setEvaluation] = useState({});
  const saveEvaluation = (payload) => {
    const data = { ...evaluation, ...payload };
    setEvaluation(data);
    setStorageItem('evaluation', data);
  };

  useEffect(() => {
    const storage = getStorageItem('evaluation');
    saveEvaluation(storage);
  }, []);

  return (
    <EvaluationContext.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{
        evaluation,
        saveEvaluation
      }}
    >
      {children}
    </EvaluationContext.Provider>
  );
};

function useEvaluation() {
  const context = useContext(EvaluationContext);

  return context;
}

export { EvaluationProvider, useEvaluation };
